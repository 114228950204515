
import ExistSession from "components/Modals/ExistSession";
import { showToast } from "components/Partials/Toast";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Spinner
} from "reactstrap";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import { alphanumeric } from "utils/helpers";
import { GoogleLogin } from "@react-oauth/google";
// import { GoogleLogin } from "react-google-login";
// import { useHistory } from "react-router-dom";
// import axios from "axios";

const Login = () => {
  const useAuth = UseAuth();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const onLogin = () => {
    setLoading(true);
    const user = {
      username: username,
      password: password,
    };

    API.post("auths/login", user)
      .then((res) => {
        // console.log(res.data.data)
        setLoading(false);
        // setMessage(res.data)
        const data = {
          accessToken: res.data.data.accessToken,
          // sessionId: res.data.data.sessionId ? res.data.data.sessionId : 'xxx'
        };
        useAuth.login(data);
      })
      .catch((err) => {
        // console.log("Data Error : ", err.response.data.data.sessionId);
        setLoading(false);
        if (err.response.data.code === 409) setIsOpen(true);
        setMessage(err.response.data);
        // console.log("Data Error : ", err.response.data)
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  // const googleLogin = useGoogleLogin({
  //   onSuccess: (tokenResponse) => {
  //     console.log("Data Login with Google : ", tokenResponse);

  //     axios
  //       .get("https://www.googleapis.com/oauth2/v3/userinfo", {
  //         headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
  //       })
  //       .then((res) => {
  //         console.log("Data User Info Login with Google : ", res.data);
  //       })
  //       .catch((err) => console.log(err));

  // const data = {
  //   tokenId: tokenResponse.credential,
  // };

  // API.post("auths/signinGoogle", data)
  //   .then((res) => {
  //     console.log("Data Login with Google : ", res.data.data);
  //     setLoading(false);
  //     // setMessage(res.data)
  //     const data = {
  //       accessToken: res.data.data.accessToken,
  //       // sessionId: res.data.data.sessionId ? res.data.data.sessionId : 'xxx'
  //     };
  //     useAuth.login(data);
  //   })
  //   .catch((err) => {
  //     // console.log("Data Error : ", err.response.data.data.sessionId);
  //     setLoading(false);
  //     if (err.response.data.code === 409) setIsOpen(true);
  //     setMessage(err.response.data);
  //     // console.log("Data Error : ", err.response.data)
  //     showToast(
  //       err.response.data.message,
  //       err.response.data.status.toLowerCase()
  //     );
  //   });
  //   },
  // });

  const responseGoogle = (response) => {
    // console.log(response);

    const data = {
      tokenId: response.credential,
    };
    API.post("auths/signinGoogle", data)
      .then((res) => {
        console.log("Data Login with Google : ", res.data.data);
        setLoading(false);
        // setMessage(res.data)
        const data = {
          accessToken: res.data.data.accessToken,
          // sessionId: res.data.data.sessionId ? res.data.data.sessionId : 'xxx'
        };
        useAuth.login(data);
      })
      .catch((err) => {
        // console.log("Data Error : ", err.response.data.data.sessionId);
        setLoading(false);
        if (err.response.data.code === 409) setIsOpen(true);
        setMessage(err.response.data);
        // console.log("Data Error : ", err.response.data)
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
      });
  };

  // const getProfile = (auth) => {
  //   API.get('users/getProfile', {
  //     headers: { 'Authorization': "Bearer " + auth.data.accessToken }
  //   }).then(res => {
  //     // console.log(res.data)
  //     const data = res.data.data
  //     setLoading(false)
  //     setMessage(auth)
  //     useAuth.login({ user: data, ...auth.data });
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }

  // const getEo = (auth) => {
  //   API.get('eventOrganizers/getEventOrganizer', {
  //     headers: { 'Authorization': "Bearer " + auth.data.accessToken }
  //   }).then(res => {
  //     // console.log(res.data)
  //     const data = res.data.data
  //     setLoading(false)
  //     setMessage(auth)
  //     useAuth.login({ user: data, ...auth.data });
  //   }).catch(err => {
  //     console.log(err)
  //   })
  // }

  return (
    <>
      <Col xl="9" lg="10" md="11">
        <Card className="bg-secondary shadow border-0 my-0 my-lg-6">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <h1>Masuk</h1>
              <div>Waktunya untuk kamu bergabung dengan event-event seru</div>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Username"
                    type="text"
                    value={username}
                    onChange={(event) =>
                      setUsername(alphanumeric(event.target.value))
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Password"
                    type="password"
                    autoComplete="new-password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              {/* <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Ingat saya</span>
                </label>
              </div> */}

              {/* <Alert props={{ message, setMessage }} /> */}

              <div className="text-center">
                <Button
                  disabled={loading}
                  onClick={() => onLogin()}
                  className="my-4 w-100 text-white bg-hievent"
                  color="bg-hievent"
                  type="button"
                >
                  {loading ? <Spinner size="sm" /> : null} Masuk
                </Button>
                <GoogleLogin
                  onSuccess={responseGoogle}
                  onError={responseGoogle}
                  isSignedIn={false}
                  render={(renderProps) => (
                    <Button
                      className="my-4 w-100 text-white bg-hievent border"
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                    >
                      {loading ? <Spinner size="sm" /> : null} Masuk dengan
                      Google
                    </Button>
                  )}
                />

                {/* <Button
                  disabled={loading}
                  onClick={() => googleLogin()}
                  className="my-4 w-100"
                  color="bg-hievent"
                  style={{
                    color: "#9870C5",
                    border: "1.5px solid #9870C5",
                    display: "flex",
                    flexDirection: "row",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-google"
                    viewBox="0 0 16 16"
                  >
                    <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                  </svg>
                  {loading ? <Spinner size="sm" /> : null} Masuk dengan Google
                </Button> */}
              </div>
            </Form>

            {/* <div className="text-muted text-center mt-2 mb-3">
              <small>Sign in with</small>
            </div>
            <div className="btn-wrapper text-center">
              <Button
                className="btn-neutral btn-icon w-100"
                color="default"
                href="#pablo"
                onClick={() => null}
              >
                <span className="btn-inner--icon">
                  <img
                    alt="hievents"
                    src={
                      require("../../assets/img/icons/common/google.svg")
                        .default
                    }
                  />
                </span>
                <span className="btn-inner--text">Google</span>
              </Button>
            </div> */}
          </CardBody>
        </Card>
        <Row className="mt-3">
          <Col xs="6">
            <a className="text-light" href="/auth/forgot-pasword">
              <small>Lupa password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <Link to="/auth/register">
              <small>Daftar akun baru</small>
            </Link>
          </Col>
        </Row>
      </Col>

      <ExistSession props={{ isOpen, setIsOpen, message, setMessage }} />
    </>
  );
};

export default Login;
