import { Link } from "react-router-dom";

import { DropdownMenu, DropdownItem } from "reactstrap";

const Notifikasi = ({ notifications }) => {
  return (
    <DropdownMenu className="dropdown-menu-arrow pt-3 mt-2 ml-4" right>
      {notifications.length > 0 ? (
        <>
          {notifications.map((item, index) => (
            <>
              <DropdownItem className="d-flex flex-row align-items-center px-4">
                <i className="ni ni-check-bold" />
                <div className="d-flex flex-column">
                  <span>{item.title}</span>
                  <small>{item.description}</small>
                </div>
              </DropdownItem>
              <DropdownItem divider />
            </>
          ))}
          <DropdownItem
            className="d-flex flex-row align-items-center justify-content-center px-4"
            to={{
              pathname: "/notifikasi",
              state: { notifications } // Mengirim array of object di sini
            }}
            tag={Link}
          >
            <span className="text-center">All notification</span>
          </DropdownItem>
        </>
      ) : (
        <DropdownItem className="d-flex flex-row align-items-center px-4">
          {/* <i className="ni ni-check-bold" /> */}
          <div className="d-flex flex-column">
            <span>Not Found Notifications</span>
          </div>
        </DropdownItem>
      )}
    </DropdownMenu>
  );
};

export default Notifikasi;
