import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  Badge,
  Container,
  Row,
  Col,
  Media,
  Button,
  ButtonGroup,
  CardFooter,
} from "reactstrap";
import Map, { Marker } from "react-map-gl";
import { UseAuth } from "routes/useAuth";
import API from "utils/API";
import EventPopuler from "views/event-populer/Index";
import Avatar from "react-avatar";
import ReportEvent from "components/Modals/ReportEvent";
import EventSave from "components/Partials/EventSave";
import EventShare from "components/Partials/EventShare";
import Follow from "components/Partials/Follow";
import EOMedia from "components/Partials/EOMedia";
import EventRegister from "components/Partials/EventRegister";
import { connect } from "react-redux";
import { showToast } from "components/Partials/Toast";
import AttendEvent from "components/Modals/AttendEvent";
import Loading from "components/Partials/Loading";
import { CertificatePreview } from "components/Partials/CertificatePreview";
import { capitalizeFirstLetter } from "utils/helpers";
import logo from "../../assets/img/brand/brand-color.png";
import QRCode from "qrcode";
import ModalImage from "react-modal-image";
import MetaTags from "components/Partials/MetaTags";
import { useMediaQuery } from "react-responsive";
import { BsThreeDotsVertical } from "react-icons/bs";
import { motion } from "framer-motion";
import { useLocalStorage } from "routes/useLocalStorage";


const Index = ({ match, USER }) => {
  const [event, setEvent] = useState({});
  const [directPath, setDirectPath] = useLocalStorage("direct-path", "");

  const [userRegister, setUserRegister] = useState({});
  const [userRegistered, setUserRegistered] = useState(false);

  const [userAttend, setUserAttend] = useState({});
  const [loading, setLoading] = useState(true);
  const [linkAbsensi, setLinkAbsensi] = useState(
    process.env.REACT_APP_currentURL + "/absensi/"
  );
  const [codeAbsen, setCodeAbsen] = useState("");
  const [linkCode, setLinkCode] = useState(
    process.env.REACT_APP_currentURL + "/sertifikat/"
  );
  const [description, setDescription] = useState("");

  const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_TOKEN;
  const [viewState, setViewState] = useState({});
  const [activeTab, setActiveTab] = useState("tentang");

  const navigation = useHistory();
  const useAuth = UseAuth();

  const isSmallScreen = useMediaQuery({ maxWidth: 639 });
  const pathname = window.location.pathname.split("/")[1];

  const [showOptions, setShowOptions] = useState(false);

  const dropdownVariants = {
    open: {
      height: "auto",
      opacity: 1,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.3 },
      },
    },
    closed: {
      height: 0,
      opacity: 0,
      transition: {
        height: { duration: 0.3 },
        opacity: { duration: 0.3 },
      },
    },
  };

  useEffect(() => {
    getEvent();
  }, [match.params.slug, USER]);

  // console.log("Data Match : ", match);

  const getEvent = () => {
    setLoading(true);

    let url = "";
    if (isNaN(match.params.slug)) {
      if (match.params.slug.split("=").length === 1)
        url = "events/getEvent/" + match.params.slug;
      else
        url =
          "events/getEventByEventOrganizer/" + match.params.slug.split("=")[0];
    } else {
      if (
        window.location.pathname
          .replace(match.params.slug, "")
          .includes("absensi")
      ){
        if(!useAuth.auth){
          setDirectPath(window.location.pathname);
          navigation.replace('/auth/login');
        }
        url = "events/getEventByCodeForAttend/" + match.params.slug; 
      }
      else url = "events/getEventByGeneratedEventCode/" + match.params.slug;
    }

    // const headers = useAuth.auth
    //   ? { headers: { Authorization: `Bearer ${useAuth.auth.accessToken}` } }
    //   : {};

    // const isValidSlug =
    //   isNaN(match.params.slug) && match.params.slug.split("=").length === 1;


    // console.log(headers);
    // console.log(isValidSlug);
    API.get(
      url,
      isNaN(match.params.slug) && match.params.slug.split("=").length === 1
        ? {}
        : { headers: { Authorization: "Bearer " + useAuth.auth?.accessToken } }
    )
      .then((res) => {
        // console.log(res.data.data)
        const data = res.data.data;
        setEvent(res.data.data);
        if (data.longitude && data.latitude)
          setViewState({
            longitude: parseFloat(data.longitude),
            latitude: parseFloat(data.latitude),
            zoom: 13,
          });

        setLinkAbsensi(linkAbsensi + data.generatedEventCode);
        QRCode.toDataURL(linkAbsensi + data.generatedEventCode)
          .then((url) => {
            setCodeAbsen(url);
          })
          .catch((err) => {
            console.error(err);
          });

        QRCode.toDataURL(linkCode + "hievents")
          .then((url) => {
            setLinkCode(url);
          })
          .catch((err) => {
            console.error(err);
          });

        const div = document.createElement("div");
        div.innerHTML = data.description;
        setDescription(div.textContent || div.innerText || "");

        if (data?.user?._id === USER._id) {
          getEventRegistration(data._id);
          getUserAttendEvents(data._id);
        } else setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        // console.log(err.response.data)
        showToast(
          err.response.data.message,
          err.response.data.status.toLowerCase()
        );
        if (err.response?.data.code === 401) {
          navigation.push("/");
        } else if (err.response?.data.code === 404) {
          navigation.push("/not-found");
        }
      });
  };

  // useEffect(() => {
  //   console.log("event");
  //   console.log(event);
  // },[event]);
  // console.log("Data Event : ", event)

  const getEventRegistration = (_id) => {
    API.get(
      "eventRegistrations/getUserAttendEvents/" +
        _id +
        "?page=0&perPage=0&status=registered",
      !useAuth.auth
        ? {}
        : {
            headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
          }
    )
      .then((res) => {
        setLoading(false);
        setUserRegister(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  const getUserAttendEvents = (_id) => {
    API.get(
      "eventRegistrations/getUserAttendEvents/" +
        _id +
        "?page=0&perPage=0&status=attended",
      !useAuth.auth
        ? {}
        : {
            headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
          }
    )
      .then((res) => {
        // console.log(res.data)
        setLoading(false);
        setUserAttend(res.data);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err.response.data);
      });
  };

  const getUserEventRegistration = () => {
    API.get(
      `/eventRegistrations/getUserEventRegistrations?status=registered&perPage=1000`,
      !useAuth.auth
        ? {}
        : {
            headers: { Authorization: "Bearer " + useAuth.auth.accessToken },
          }
    )
      .then((res) => {
        res.data?.data?.map((item) => {
          if (item.event?._id === event._id) {
            setUserRegistered(true);
          }
        });
      })
      .catch((err) => {
        setUserRegistered(false);
        // console.log(err.response.data);
      });
  };

  const copyLink = () => {
    navigator.clipboard.writeText(linkAbsensi);
    showToast("Berhasil menyalin link absensi", "success");
  };

  const downloadQRCode = (e) => {
    // var doc = new jsPDF();
    // var elementHTML = document.getElementById('qrcode-absen');
    // doc.html(elementHTML, {
    //     callback: function (doc) {
    //         doc.save("absensi"+event.title.replace(' ', '_').toLowerCase()+ '.pdf');
    //     },
    //     x: 5,
    //     y: 3.5,
    //     width: 204,
    //     windowWidth: 840,
    // });

    const downloadLink = document.createElement("a");
    downloadLink.href = codeAbsen;
    downloadLink.download =
      "absensi_" + event.title.replace(" ", "_").toLowerCase() + ".jpg";
    downloadLink.click();

    e.preventDefault();
  };

  useEffect(() => {
    getUserEventRegistration();
  }, [USER, event]);

  const listUserRegister = () => {
    const data = [];
    userRegister.data?.map((element, index) => {
      if (index < 5)
        data.push(
          <div
            key={element._id}
            className="d-flex flex-row align-items-center mx-3 my-2"
          >
            <Avatar
              className="logo-eo"
              name={element.user?.name}
              src={element.user?.photo?.url}
              round={true}
              size={45}
            />
            <h4 className="mb-0 ml-2">{element.user?.name}</h4>
            <p className="mb-0 ml-auto">{element.user?.city[0]?.name}</p>
          </div>
        );
    });
    return data;
  };

  const listUserAttend = () => {
    const data = [];
    userAttend.data?.map((element, index) => {
      if (index < 5)
        data.push(
          <div
            key={element._id}
            className="d-flex flex-row align-items-center mx-3 my-2"
          >
            <Avatar
              className="logo-eo"
              name={element.user?.name}
              src={element.user?.photo?.url}
              round={true}
              size={45}
            />
            <h4 className="mb-0 ml-2">{element.user?.name}</h4>
            <p className="mb-0 ml-auto">{element.user?.city[0]?.name}</p>
          </div>
        );
    });
    return data;
  };

  const accessEventLink = () => {
    if (event.accessEventLink.search("https") === 0)
      window.open(event.accessEventLink, "_blank");
    else window.open("https://" + event.accessEventLink, "_blank");
  };

  const accessGroupLink = () => {
    if (event.groupLink.search("https") === 0)
      window.open(event.groupLink, "_blank");
    else window.open("https://" + event.groupLink, "_blank");
  };

  // Modified By Rama 9-11-2023
  const validationAttends = () => {
    const currentTime = new Date();
    const startEvent = new Date(event.startDate);
    const deadlineAttendance = new Date(event.deadlineAttendance);

    return currentTime >= startEvent && currentTime <= deadlineAttendance;
  };

  if (loading) return <Loading />;

  return (
    <div>
      <MetaTags
        url={"https://hievents.co/event/" + event.slug}
        title={event.title}
        image={event.image?.url}
        description={description}
      />
      {!isSmallScreen ? (
        <Container className="pt-7" fluid>
          <button
            className="color-hievent"
            style={{
              backgroundColor: "transparent",
              border: "none",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "fit-content",
              padding: 10,
            }}
            onClick={() => navigation.goBack()}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
                fill="#9870C5"
              />
            </svg>
            Kembali
          </button>
          <Row>
            <Col xl="8" lg="8" md="6">
              <Card className="shadow">
                <CardBody className="p-4">
                  <Row>
                    <Col
                      lg="5"
                      className="align-items-center justify-content-center"
                    >
                      <ModalImage
                        className="img-fluid w-100 h-100 rounded-lg"
                        small={event.image?.url}
                        large={event.image?.url}
                        alt="hievents"
                      />
                    </Col>
                    <Col lg="7" className="pl-2 pr-4 mx-4 mx-lg--2">
                      <h3 className="pb-1">
                        {event.title}{" "}
                        {/* <Badge color="success pb-2" pill>
                        {event.eventType?.name}
                      </Badge> */}
                      </h3>
                      <h3
                        className="text-success fw-bold"
                        style={{ fontWeight: 700 }}
                      >
                        {capitalizeFirstLetter(event.paidStatus)}
                      </h3>
                      {window.location.pathname
                        .replace(match.params.slug, "")
                        .includes("event-terdaftar") ? null : (
                        <Row className="py-1">
                          <Col
                            xs="6"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              gap: "4px",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 15 15"
                              fill="none"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.25 2.5H11.875C12.5625 2.5 13.125 3.0625 13.125 3.75V12.5C13.125 13.1875 12.5625 13.75 11.875 13.75H3.125C2.43125 13.75 1.875 13.1875 1.875 12.5L1.88125 3.75C1.88125 3.0625 2.43125 2.5 3.125 2.5H3.75V1.25H5V2.5H10V1.25H11.25V2.5ZM3.125 6.25V12.5H11.875V6.25H3.125ZM11.875 5H3.125V3.75H11.875V5ZM10.625 8.125H7.5V11.25H10.625V8.125Z"
                                fill="#737373"
                              />
                            </svg>
                            <small className="text-sm">Batas pendaftaran</small>
                          </Col>
                          <Col
                            xs="6"
                            style={{ display: "flex", justifyContent: "end" }}
                          >
                            <small className="text-sm">
                              {moment(event.deadlineRegistration).format(
                                "DD MMMM YYYY"
                              )}
                            </small>
                          </Col>
                        </Row>
                      )}
                      <Row className="py-1">
                        <Col
                          xs="6"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.875 13.75C12.5625 13.75 13.125 13.1875 13.125 12.5V3.75C13.125 3.0625 12.5625 2.5 11.875 2.5H11.25V1.25H10V2.5H5V1.25H3.75V2.5H3.125C2.43125 2.5 1.88125 3.0625 1.88125 3.75L1.875 12.5C1.875 13.1875 2.43125 13.75 3.125 13.75H11.875ZM5.625 8.125V6.875H4.375V8.125H5.625ZM3.125 5H11.875V3.75H3.125V5ZM11.875 6.25V12.5H3.125V6.25H11.875ZM10.625 8.125V6.875H9.375V8.125H10.625ZM8.125 8.125H6.875V6.875H8.125V8.125Z"
                              fill="#737373"
                            />
                          </svg>
                          <small className="text-sm">Mulai Event</small>
                        </Col>
                        <Col
                          xs="6"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <small className="text-sm">
                            {moment(event.startDate).format("DD MMMM YYYY")}
                          </small>
                        </Col>
                      </Row>
                      <Row className="py-0">
                        <Col xs="6">
                          {/* <small className="text-sm">Berakhir Event</small> */}
                        </Col>
                        <Col
                          xs="6"
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M4.14518 1.06496L3.24329 0L0 2.66525L0.901825 3.73022L4.14518 1.06496ZM10.7566 0.000976562L13.9999 2.66623L13.098 3.73119L9.85469 1.06594L10.7566 0.000976562ZM7.35194 4.29206H6.29602V8.45262L9.63976 10.4289L10.1677 9.57597L7.35194 7.93255V4.29206ZM6.99994 1.51849C3.50133 1.51849 0.664429 4.313 0.664429 7.75933C0.664429 11.2057 3.50133 14.0002 6.99994 14.0002C10.4985 14.0002 13.3355 11.2057 13.3355 7.75933C13.3355 4.313 10.4985 1.51849 6.99994 1.51849ZM2.07239 7.7588C2.07239 10.4354 4.28278 12.6128 7.00001 12.6128C9.71724 12.6128 11.9276 10.4354 11.9276 7.7588C11.9276 5.08218 9.71724 2.90482 7.00001 2.90482C4.28278 2.90482 2.07239 5.08218 2.07239 7.7588Z"
                              fill="black"
                              fill-opacity="0.54"
                            />
                          </svg>
                          <small className="text-sm">
                            {moment(event.startDate).format("LT") +
                              " - " +
                              moment(event.endDate).format("LT")}
                          </small>
                        </Col>
                      </Row>
                      {window.location.pathname
                        .replace(match.params.slug, "")
                        .includes("event-terdaftar") ? (
                        <Row>
                          <Col xs="6">
                            <small className="text-sm">Batas absensi</small>
                          </Col>
                          <Col xs="6">
                            <small className="text-sm">
                              {moment(event.deadlineAttendance).format(
                                "DD MMM YYYY HH:mm"
                              )}
                            </small>
                          </Col>
                        </Row>
                      ) : null}
                      <div
                        className="d-flex flex-row mt-2 ml-1 justify-content-center"
                        style={{ gap: "20px" }}
                      >
                        <Badge color="warning p-3 border border-warning" pill>
                          {event.eventType?.name}
                        </Badge>
                        <Badge color="success p-3 border border-success" pill>
                          {event.eventContext?.name}
                        </Badge>
                      </div>
                      <hr className="my-4"></hr>
                      <Media
                        className="align-items-center mb-1"
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          navigation.push(
                            USER._id !== event.user?._id
                              ? "/event-organizer/" + event.eventOrganizer?.slug
                              : "/event-organizer"
                          )
                        }
                      >
                        <Avatar
                          className="logo-eo"
                          name={event.eventOrganizer?.name}
                          src={event.eventOrganizer?.logo.url}
                          round={true}
                          size={45}
                        />
                        <Media className="ml-2">
                          <span className="mb-0 text-sm text-success font-weight-bold">
                            {event.eventOrganizer?.name}
                          </span>
                        </Media>
                      </Media>

                      <div className="d-flex flex-row align-items-center mt-2">
                        {USER._id !== event.user?._id ? (
                          <Follow eventOrganizer={event.eventOrganizer?._id} />
                        ) : null}

                        <EOMedia eventOrganizer={event.eventOrganizer} />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardBody className="p-4">
                  <div className="d-flex flex-row align-items-center mb-3">
                    <h3
                      className="color-hievent mr-3 text-lg"
                      style={{ fontWeight: 700 }}
                    >
                      Tentang
                    </h3>
                    <EventShare event={event} />
                    {USER._id !== event.user?._id ? (
                      <>
                        <EventSave eventId={event._id} />
                        <ReportEvent eventId={event._id} />
                      </>
                    ) : null}
                  </div>
                  {/* <span className="text-sm">{event.description}</span> */}
                  <div style={
                    {
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      padding: "2% 0"
                    }
                  }>
                    {event.thumbnailDescription.url ? (
                      <div style={{
                        width: "60%",
                        height: "60vh",
                        backgroundColor: "transparent"
                      }}>
                        <img src={event.thumbnailDescription?.url}  alt="Thumbnail Description" style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "fill"
                        }}/>
                      </div>
                    ) : null}
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: event.description }}
                  />
                </CardBody>
              </Card>

              {event.location === "offline" ? (
                <Card className="shadow mt-4">
                  <CardBody className="p-4">
                    <div className="d-flex flex-row align-items-center mb-2">
                      <h3 className="color-hievent mr-auto">Lokasi Event</h3>
                      <Badge className="px-4" color="primary" pill>
                        {event.city?.name}
                      </Badge>
                    </div>
                    <span className="text-sm">
                      <i className="fa fa-map-marker" /> {event.address}
                    </span>

                    {event.latitude && event.longitude ? (
                      <div className="mt-2">
                        <Map
                          {...viewState}
                          dragRotate={false}
                          doubleClickZoom={false}
                          touchZoom={false}
                          touchRotate={false}
                          width="100%"
                          height={400}
                          mapStyle="mapbox://styles/mapbox/streets-v11"
                          mapboxApiAccessToken={MAPBOX_TOKEN}
                        >
                          <Marker {...viewState} anchor="center">
                            <div className="ml--1 mt--3">
                              <i
                                className="fa fa-map-marker ml--2 fa-2x"
                                style={{ color: "#FF0000" }}
                              />
                            </div>
                          </Marker>
                        </Map>
                      </div>
                    ) : null}
                  </CardBody>
                </Card>
              ) : (
                <Card className="shadow mt-4">
                  <CardBody className="p-4">
                    <div className="d-flex flex-row align-items-center mb-2">
                      <h3
                        className="color-hievent mr-auto text-lg"
                        style={{ fontWeight: 700 }}
                      >
                        Online Event
                      </h3>
                    </div>
                    {window.location.pathname
                      .replace(match.params.slug, "")
                      .includes("event-terdaftar") ||
                    USER._id === event.user?._id ? (
                      <span
                        role="button"
                        className="text-sm mb-2 text-blue"
                        onClick={() => accessEventLink()}
                      >
                        <i className="fa fa-laptop color-hievent" />{" "}
                        {event.accessEventLink}
                      </span>
                    ) : (
                      <span role="button" className="text-sm mb-2 text-warning">
                        <i className="fa fa-laptop color-hievent" /> Link event
                        dapat diakses setelah mendaftar
                      </span>
                    )}
                  </CardBody>
                </Card>
              )}

              {event.groupLink === "" ||
              event.groupLink === undefined ? null : (
                <Card className="shadow mt-4">
                  <CardBody className="p-4">
                    <div className="d-flex flex-row align-items-center mb-2">
                      <h3 className="color-hievent mr-auto">Group Event</h3>
                    </div>
                    {window.location.pathname
                      .replace(match.params.slug, "")
                      .includes("event-terdaftar") ||
                    USER._id === event.user?._id ? (
                      <span
                        role="button"
                        className="text-sm mb-2 text-blue"
                        onClick={() => accessGroupLink()}
                      >
                        <i className="fa fa-laptop" /> {event.groupLink}
                      </span>
                    ) : (
                      <span role="button" className="text-sm mb-2 text-warning">
                        <i className="fa fa-laptop" /> Link group event dapat
                        diakses setelah mendaftar
                      </span>
                    )}
                  </CardBody>
                </Card>
              )}
            </Col>

            <Col xl="4" lg="4" md="6" className="mt-4 mt-md-0">
              <Card className="shadow">
                <CardBody className="p-5">
                  <h3>{event.title}</h3>
                  <Row className="mt-2">
                    <Col xs="6">
                      <small className="text-sm text-start">
                        Batas pendaftaran
                      </small>
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end">
                      <small className="text-sm text-end">
                        {moment(event.deadlineRegistration).format(
                          "DD MMM YYYY"
                        )}
                      </small>
                    </Col>
                  </Row>
                  <Row className="mt-2">
                    <Col xs="6">
                      <small className="text-sm">Tiket</small>
                    </Col>
                    <Col xs="6" className="d-flex justify-content-end">
                      <h4>Tersedia</h4>
                    </Col>
                  </Row>
                  <hr className="mt-3 mb-2"></hr>
                  <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                    {capitalizeFirstLetter(
                      event.paidStatus === "free" ? "Gratis" : "Berbayar"
                    )}
                  </h3>
                  {/* <Row>
                                        <Col lg="6">
                                            <span className="text-sm text-danger"><i className="fa fa-id-card" /> Tiket Saya</span>
                                        </Col>
                                        <Col lg="6">
                                            <small className="text-sm">1x Regular</small>
                                        </Col>
                                    </Row> */}
                  <hr className="mb-3 mt-2"></hr>

                  {USER._id !== event.user?._id ? (
                    userRegistered ? (
                      <AttendEvent event={event} />
                    ) : (
                      <EventRegister
                        event={event}
                        accessGroupLink={accessGroupLink}
                      />
                    )
                  ) : event.privateEvent ? (
                    <div className="py-2 bg-hievent text-center rounded-lg mt-2">
                      <span className="text-white" style={{ fontWeight: 700 }}>
                        Kode akses event
                      </span>
                      <h2 className="text-white mb-0">
                        {event.generatedEventCode}
                      </h2>
                    </div>
                  ) : null}
                </CardBody>
              </Card>

              <Card
                className={
                  window.location.pathname
                    .replace(match.params.slug, "")
                    .includes("event-terdaftar")
                    ? "shadow"
                    : "shadow mt-4"
                }
              >
                <CardBody className="p-4">
                  <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                    Benefit
                  </h3>

                  <Row>
                    {event.benefitIds?.map((element) => (
                      <Col key={element._id} className="my-2" md="6">
                        <div className="d-flex align-items-center">
                          <Badge className="bg-hievent py-2 px-2 m-0" pill>
                            {" "}
                          </Badge>
                          <span
                            className="text-sm m-0 ml-2"
                            style={{ fontWeight: 700 }}
                          >
                            {element.name}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardBody className="p-4">
                  <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                    Prasyarat
                  </h3>

                  <Row>
                    {event.preconditionIds?.map((element) => (
                      <Col key={element._id} className="my-2" md="6">
                        <div
                          key={element._id}
                          className="d-flex align-items-center"
                        >
                          <Badge className="bg-hievent py-2 px-2 m-0" pill>
                            {" "}
                          </Badge>
                          <span
                            className="text-sm m-0 ml-2"
                            style={{ fontWeight: 700 }}
                          >
                            {element.name}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>

              <Card className="shadow mt-4">
                <CardBody className="p-4">
                  <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                    Bahasa
                  </h3>

                  <Row>
                    {event.languageIds?.map((element) => (
                      <Col key={element._id} className="my-2" md="4">
                        <div
                          key={element._id}
                          className="d-flex align-items-center"
                        >
                          <Badge className="bg-hievent py-2 px-2 m-0" pill>
                            {" "}
                          </Badge>
                          <span
                            className="text-sm m-0 ml-2"
                            style={{ fontWeight: 700 }}
                          >
                            {element.name}
                          </span>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {USER._id === event.user?._id ? (
            <Row>
              <Col xl="6" lg="6" md="6" className="mt-4">
                <Card className="shadow">
                  <CardBody className="p-4">
                    <h3
                      className="text-lg color-hievent"
                      style={{ fontWeight: 700 }}
                    >
                      Link absensi
                    </h3>
                    <h3 className="text-success">{linkAbsensi}</h3>
                    <Button
                      className="btn btn-sm px-3"
                      color="primary"
                      onClick={() => copyLink()}
                    >
                      Salin link
                    </Button>
                    <p className="text-sm mt-2">
                      *Bagikan Link Absensi kepada peserta event agar mereka
                      dapat mengisi kehadiran / absensi pada Event ini baik di
                      Zoom, Gmeet / di WhatsApp. Jika Event ini bersertifikat,
                      maka Peserta event akan mendapatkan sertifikat secara
                      otomatis dengan mengisi kehadiran melalui link diatas.
                    </p>
                  </CardBody>
                </Card>

                <Card className="shadow mt-4">
                  <CardBody className="p-4">
                    <h3>Siapa saja yang bergabung</h3>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <i className="fa fa-users fa-lg"></i>
                      <h3 className="text-success mb-0 ml-2">
                        {userRegister.count}
                        {event.maximumTicket > 0
                          ? " / " + event.maximumTicket
                          : ""}{" "}
                        Peserta telah bergabung
                      </h3>
                    </div>
                    <hr className="m-3"></hr>
                    {listUserRegister()}
                  </CardBody>
                  {userRegister.count > 0 ? (
                    <CardFooter
                      className="d-flex rounded-bottom border-0 p-3 align-items-center bg-gradient-primary"
                      to={
                        "/user-terdaftar/" +
                        event._id +
                        "=" +
                        userRegister.count
                      }
                      tag={Link}
                    >
                      <span className="w-100 text-center text-white">
                        Lihat Semua
                      </span>
                    </CardFooter>
                  ) : null}
                </Card>

                <Card className="shadow mt-4">
                  <CardBody className="p-4">
                    <h3>Siapa saja yang mengisi absen</h3>
                    <div className="d-flex flex-row align-items-center justify-content-center">
                      <i className="fa fa-users fa-lg"></i>
                      <h3 className="text-success mb-0 ml-2">
                        {userAttend.count} peserta mengisi absen
                      </h3>
                    </div>
                    <hr className="m-3"></hr>
                    {listUserAttend()}
                  </CardBody>
                  {userAttend.count > 0 ? (
                    <CardFooter
                      className="d-flex rounded-bottom border-0 p-3 align-items-center bg-gradient-primary"
                      to={"/user-absensi/" + event._id + "=" + userAttend.count}
                      tag={Link}
                    >
                      <span className="w-100 text-center text-white">
                        Lihat Semua
                      </span>
                    </CardFooter>
                  ) : null}
                </Card>

                {/* <Card className="shadow mt-4">
                                <CardBody className="p-4">
                                    <h3>Bagaimana event berakhir ?</h3>
                                    <p className="text-sm mt-2">*Event akan secara otomatis berakhir disaat tanggal mulai Event berakhir pada pukul 00:00. Contoh Jika Tanggal mulai Event 22 Juni maka ketika pergantian tanggal di tengah malam ke 23 Juni, Event akan secara otomatis berakhir.</p>
                                    <p className="text-sm mt-2">*Anda juga dapat menghapus Event ini untuk menghapus event. </p>
                                </CardBody>
                            </Card> */}
              </Col>

              <Col xl="6" lg="6" md="6" className="mt-4">
                <Card className="shadow">
                  <CardBody className="p-4">
                    <h3>Link absensi dengan Barcode</h3>
                    {/* <h3 className="text-success">{linkAbsensi}</h3> */}
                    <Col className="mt-3 text-center" lg="12">
                      <img
                        id="qrcode-absen"
                        src={codeAbsen}
                        style={{ width: 360 }}
                      ></img>
                    </Col>
                    <Col className="mt-3 text-center" lg="12">
                      <Button
                        className="btn btn-sm"
                        color="primary"
                        onClick={(event) => downloadQRCode(event)}
                      >
                        Download QRCode
                      </Button>
                    </Col>
                    <p className="text-sm mt-3">
                      *Perintahkan Peserta Event untuk meng-scan Barcode diatas
                      ini. agar mereka dapat mengisi kehadiran pada Event ini.
                      Jika Event ini bersertifikat, maka Peserta event akan
                      mendapatkan sertifikat secara otomatis dengan mengabsen
                      melalui scan QRCode diatas.
                    </p>
                    <p className="text-sm mt-2">
                      *Mengisi Kehadiran menggunakan QRCode lebih disarankan
                      untuk Event yang bersifat Offline.
                    </p>
                  </CardBody>
                </Card>

                {event.certifiedEvent ? (
                  <Card className="shadow mt-4">
                    <CardBody className="p-4">
                      <h3>Sertifikat</h3>
                      <p className="text-sm m-0 mt-2">
                        *Sertifikat akan dibagikan secara otomatis kepada
                        Peserta yang sudah mengisi absen pada Link Absensi.
                      </p>
                      <button
                        onClick={(event) => CertificatePreview(event)}
                        className="btn btn-sm btn-warning mb-2"
                      >
                        Unduh Preview
                      </button>
                      <Col id="certificate" md="12" className="p-0">
                        <img
                          className="w-100 h-100 rounded-lg"
                          style={{ objectFit: "cover" }}
                          src={
                            event.certificateFile.url
                              ? event.certificateFile.url
                              : process.env.REACT_APP_urlCertificate
                          }
                          alt="hievents"
                          crossOrigin="true"
                        />
                        <h1
                          className="w-100 text-center display-4 certificate-name"
                          style={{ fontWeight: "bolder" }}
                        >
                          NAMA PESERTA
                        </h1>
                        {/* <div className="w-100 text-center certificate-logo">
                          <img
                            className="certificate-logo-detail"
                            src={logo}
                            alt="hievents"
                          />
                        </div> */}
                        <div className="w-100 certificate-code">
                          <img src={linkCode} className="qrcode-detail"></img>
                        </div>
                      </Col>
                    </CardBody>
                  </Card>
                ) : null}
              </Col>
            </Row>
          ) : null}
        </Container>
      ) : (
        <div
          style={{
            position: "relative",
            width: "100vw",
            height: "100vh",
            overflowX: "hidden",
          }}
        >
          <div
            style={{
              position: "absolute",
              zIndex: 1,
            }}
          >
            <ModalImage
              small={event.image?.url}
              large={event.image?.url}
              alt="hievents"
            />
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              padding: 20,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "grid",
                gridTemplateColumns: "1fr 40px",
              }}
            >
              <div>
                {/* Button Arrow */}
                <button
                  className="color-white bg-hievent"
                  style={{
                    border: "none",
                    position: "fixed",
                    cursor: "pointer",
                    width: "fit-content",
                    padding: 10,
                    zIndex: 100,
                    opacity: 0.7,
                    borderRadius: 100,
                  }}
                  onClick={() => {
                    pathname.includes("event-selesai")
                      ? navigation.push("/event-terdaftar")
                      : navigation.push("/" + pathname);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M15.7049 7.41L14.2949 6L8.29492 12L14.2949 18L15.7049 16.59L11.1249 12L15.7049 7.41Z"
                      fill="#FFFFFF"
                    />
                  </svg>
                </button>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {/* Button Three Dots */}
                <button
                  className="color-white bg-hievent"
                  style={{
                    border: "none",
                    position: "fixed",
                    cursor: "pointer",
                    width: "40px",
                    height: "40px",
                    padding: 10,
                    zIndex: 100,
                    opacity: 0.7,
                    borderRadius: "50%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "white",
                  }}
                  onClick={() => setShowOptions(!showOptions)}
                >
                  <BsThreeDotsVertical size={20} />
                </button>

                <motion.div
                  className="options-container"
                  initial="closed"
                  animate={showOptions ? "open" : "closed"}
                  variants={dropdownVariants}
                  style={{
                    position: "fixed",
                    left: 250,
                    top: 60,
                    backgroundColor: "white",
                    zIndex: 99,
                    borderRadius: 5,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    padding: 5,
                    overflow: "hidden",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      justifyContent: "start",
                    }}
                  >
                    <EventSave eventId={event._id} />
                    <ReportEvent eventId={event._id} />
                    <EventShare event={event} />
                  </div>
                </motion.div>

                {showOptions && (
                  <div
                    className="options-container"
                    style={{
                      position: "fixed",
                      top: "60px", // Sesuaikan dengan posisi yang diinginkan
                      right: "20px", // Sesuaikan dengan posisi yang diinginkan
                      zIndex: 99, // Lebih rendah dari tombol tiga titik
                      backgroundColor: "white",
                      borderRadius: "2px",
                      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                      padding: "5px",
                    }}
                  >
                    <ButtonGroup
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    ></ButtonGroup>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* Add Card Event */}
          <div
            style={{
              position: "relative",
              zIndex: 1,
              width: "100%",
              padding: 20,
              paddingBottom: "120px",
              top: "35%",
            }}
          >
            <Card className="shadow">
              <CardBody>
                <Row>
                  <Col lg="7" className="pl-2 pr-4 mx-4 mx-lg--2">
                    <h3 className="pb-1">
                      {event.title}{" "}
                      {/* <Badge color="success pb-2" pill>
                        {event.eventType?.name}
                      </Badge> */}
                    </h3>
                    <h3
                      className="text-success fw-bold"
                      style={{ fontWeight: 700 }}
                    >
                      {capitalizeFirstLetter(event.paidStatus)}
                    </h3>
                    {window.location.pathname
                      .replace(match.params.slug, "")
                      .includes("event-terdaftar") ? null : (
                      <Row className="py-1">
                        <Col
                          xs="6"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "row",
                            gap: "4px",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                          >
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M11.25 2.5H11.875C12.5625 2.5 13.125 3.0625 13.125 3.75V12.5C13.125 13.1875 12.5625 13.75 11.875 13.75H3.125C2.43125 13.75 1.875 13.1875 1.875 12.5L1.88125 3.75C1.88125 3.0625 2.43125 2.5 3.125 2.5H3.75V1.25H5V2.5H10V1.25H11.25V2.5ZM3.125 6.25V12.5H11.875V6.25H3.125ZM11.875 5H3.125V3.75H11.875V5ZM10.625 8.125H7.5V11.25H10.625V8.125Z"
                              fill="#737373"
                            />
                          </svg>
                          <small className="text-sm">Batas pendaftaran</small>
                        </Col>
                        <Col
                          xs="6"
                          style={{ display: "flex", justifyContent: "end" }}
                        >
                          <small className="text-sm">
                            {moment(event.deadlineRegistration).format(
                              "DD MMMM YYYY"
                            )}
                          </small>
                        </Col>
                      </Row>
                    )}
                    <Row className="py-1">
                      <Col
                        xs="6"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M11.875 13.75C12.5625 13.75 13.125 13.1875 13.125 12.5V3.75C13.125 3.0625 12.5625 2.5 11.875 2.5H11.25V1.25H10V2.5H5V1.25H3.75V2.5H3.125C2.43125 2.5 1.88125 3.0625 1.88125 3.75L1.875 12.5C1.875 13.1875 2.43125 13.75 3.125 13.75H11.875ZM5.625 8.125V6.875H4.375V8.125H5.625ZM3.125 5H11.875V3.75H3.125V5ZM11.875 6.25V12.5H3.125V6.25H11.875ZM10.625 8.125V6.875H9.375V8.125H10.625ZM8.125 8.125H6.875V6.875H8.125V8.125Z"
                            fill="#737373"
                          />
                        </svg>
                        <small className="text-sm">Mulai Event</small>
                      </Col>
                      <Col
                        xs="6"
                        style={{ display: "flex", justifyContent: "end" }}
                      >
                        <small className="text-sm">
                          {moment(event.startDate).format("DD MMMM YYYY")}
                        </small>
                      </Col>
                    </Row>
                    <Row className="py-0">
                      <Col xs="6">
                        {/* <small className="text-sm">Berakhir Event</small> */}
                      </Col>
                      <Col
                        xs="6"
                        style={{
                          display: "flex",
                          justifyContent: "end",
                          flexDirection: "row",
                          gap: "4px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M4.14518 1.06496L3.24329 0L0 2.66525L0.901825 3.73022L4.14518 1.06496ZM10.7566 0.000976562L13.9999 2.66623L13.098 3.73119L9.85469 1.06594L10.7566 0.000976562ZM7.35194 4.29206H6.29602V8.45262L9.63976 10.4289L10.1677 9.57597L7.35194 7.93255V4.29206ZM6.99994 1.51849C3.50133 1.51849 0.664429 4.313 0.664429 7.75933C0.664429 11.2057 3.50133 14.0002 6.99994 14.0002C10.4985 14.0002 13.3355 11.2057 13.3355 7.75933C13.3355 4.313 10.4985 1.51849 6.99994 1.51849ZM2.07239 7.7588C2.07239 10.4354 4.28278 12.6128 7.00001 12.6128C9.71724 12.6128 11.9276 10.4354 11.9276 7.7588C11.9276 5.08218 9.71724 2.90482 7.00001 2.90482C4.28278 2.90482 2.07239 5.08218 2.07239 7.7588Z"
                            fill="black"
                            fill-opacity="0.54"
                          />
                        </svg>
                        <small className="text-sm">
                          {moment(event.startDate).format("LT") +
                            " - " +
                            moment(event.endDate).format("LT")}
                        </small>
                      </Col>
                    </Row>
                    {window.location.pathname
                      .replace(match.params.slug, "")
                      .includes("event-terdaftar") ? (
                      <Row>
                        <Col xs="6">
                          <small className="text-sm">Batas absensi</small>
                        </Col>
                        <Col xs="6">
                          <small className="text-sm">
                            {moment(event.deadlineAttendance).format(
                              "DD MMM YYYY HH:mm"
                            )}
                          </small>
                        </Col>
                      </Row>
                    ) : null}
                    <div
                      className="d-flex flex-row mt-2 ml-1 justify-content-center"
                      style={{ gap: "20px" }}
                    >
                      <Badge color="warning p-3 border border-warning" pill>
                        {event.eventType?.name}
                      </Badge>
                      <Badge color="success p-3 border border-success" pill>
                        {event.eventContext?.name}
                      </Badge>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            <Card>
              <CardBody className="shadow mt-2">
                <Row>
                  <Col>
                    <Media
                      className="align-items-center mb-1"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigation.push(
                          USER._id !== event.user?._id
                            ? "/event-organizer/" + event.eventOrganizer?.slug
                            : "/event-organizer"
                        )
                      }
                    >
                      <Avatar
                        className="logo-eo"
                        name={event.eventOrganizer?.name}
                        src={event.eventOrganizer?.logo.url}
                        round={true}
                        size={45}
                      />
                      <Media className="ml-2">
                        <span className="mb-0 text-sm text-success font-weight-bold">
                          {event.eventOrganizer?.name}
                        </span>
                      </Media>
                    </Media>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "5px",
                        }}
                      >
                        <Link
                          to="#"
                          className="bg-hievent"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                            color: "white",
                            width: "80px",
                            backgroundColor: "#9870C5",
                            height: "23px",
                            borderRadius: "5px",
                            fonwWeight: 600,
                            fontSize: "10px",
                          }}
                        >
                          Ikuti
                        </Link>
                        <Link
                          to="#"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: "5px",
                            width: "80px",
                            height: "23px",
                            borderRadius: "5px",
                            fonwWeight: 500,
                            fontSize: "10px",
                            color: "#626262",
                            textDecoration: "underline",
                          }}
                        >
                          Lihat Profil
                        </Link>
                      </div>
                      <div className="d-flex flex-row align-items-center mt-2">
                        {USER._id !== event.user?._id ? (
                          <Follow eventOrganizer={event.eventOrganizer?._id} />
                        ) : null}

                        <EOMedia eventOrganizer={event.eventOrganizer} />
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>

            {/* Event Terdaftar */}
            {pathname.includes("event-terdaftar") && (
              <Card
                className="shadow mt-4 mb-6"
                style={{
                  zIndex: 9999,
                }}
              >
                <CardBody className="p-4">
                  <Row>
                    <Col>
                      <div className="d-flex flex-row align-items-center mb-3">
                        <h3
                          className="mr-3 text-md"
                          style={{
                            fontWeight: 700,
                            color:
                              activeTab === "tentang" ? "black" : "#C4C4C4",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveTab("tentang")}
                        >
                          Tentang Event
                        </h3>
                        <h3
                          className="mr-3 text-md"
                          style={{
                            fontWeight: 700,
                            color:
                              activeTab === "prasyarat" ? "black" : "#C4C4C4",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveTab("prasyarat")}
                        >
                          Prasyarat
                        </h3>
                        <h3
                          className="mr-3 text-md"
                          style={{
                            fontWeight: 700,
                            color: activeTab === "review" ? "black" : "#C4C4C4",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveTab("review")}
                        >
                          Review
                        </h3>

                        {/* {USER._id !== event.user?._id ? (
                          <>
                            <EventSave eventId={event._id} />
                            <ReportEvent eventId={event._id} />
                          </>
                        ) : null} */}
                      </div>
                      <hr className="my-4"></hr>
                      {activeTab === "tentang" && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: event.description,
                          }}
                        />
                      )}
                      {activeTab === "prasyarat" && (
                        // Render Prasyarat content here
                        <Row>
                          {event.preconditionIds?.map((element) => (
                            <Col key={element._id} className="my-2" md="6">
                              <div className="d-flex align-items-center">
                                <Badge className="bg-hievent py-2 px-2" pill>
                                  {" "}
                                </Badge>
                                <span
                                  className="text-sm m-0 ml-2"
                                  style={{ fontWeight: 700 }}
                                >
                                  {element.name}
                                </span>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                      {activeTab === "review" && <span>-</span>}
                      <hr className="my-4"></hr>
                      <Row>
                        <Col>
                          <h3
                            className="color-black"
                            style={{ fontWeight: 700 }}
                          >
                            Grup Event
                          </h3>
                          <Link to={event?.groupLink} target="_BLANK">
                            {event?.groupLink ? event?.groupLink : "-"}
                          </Link>
                        </Col>
                      </Row>
                      <hr className="my-4"></hr>
                      <Row>
                        <Col>
                          <h3
                            className="color-black"
                            style={{ fontWeight: 700 }}
                          >
                            Materi Event
                          </h3>
                          <Link to={event?.accessEventLink} target="_BLANK">
                            {event?.accessEventLink
                              ? event?.accessEventLink
                              : "-"}
                          </Link>
                        </Col>
                      </Row>
                      <hr className="my-4"></hr>

                      <Row>
                        <Col>
                          <h3
                            className="color-black"
                            style={{ fontWeight: 700 }}
                          >
                            Benefit
                          </h3>
                          <Row>
                            {event.benefitIds?.map((element) => (
                              <Col key={element._id} className="my-2" md="6">
                                <div className="d-flex align-items-center">
                                  <Badge className="bg-hievent py-2 px-2" pill>
                                    {" "}
                                  </Badge>
                                  <span
                                    className="text-sm m-0 ml-2"
                                    style={{ fontWeight: 700 }}
                                  >
                                    {element.name}
                                  </span>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}

            {pathname.includes("event-selesai") && (
              <Card
                className="shadow mt-4 mb-6"
                style={{
                  zIndex: 9999,
                }}
              >
                <CardBody className="p-4">
                  <Row>
                    <Col>
                      <div className="d-flex flex-row align-items-center mb-3">
                        <h3
                          className="mr-3 text-md"
                          style={{
                            fontWeight: 700,
                            color:
                              activeTab === "tentang" ? "black" : "#C4C4C4",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveTab("tentang")}
                        >
                          Tentang Event
                        </h3>
                        <h3
                          className="mr-3 text-md"
                          style={{
                            fontWeight: 700,
                            color:
                              activeTab === "prasyarat" ? "black" : "#C4C4C4",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveTab("prasyarat")}
                        >
                          Prasyarat
                        </h3>
                        <h3
                          className="mr-3 text-md"
                          style={{
                            fontWeight: 700,
                            color: activeTab === "review" ? "black" : "#C4C4C4",
                            cursor: "pointer",
                          }}
                          onClick={() => setActiveTab("review")}
                        >
                          Review
                        </h3>

                        {/* {USER._id !== event.user?._id ? (
                          <>
                            <EventSave eventId={event._id} />
                            <ReportEvent eventId={event._id} />
                          </>
                        ) : null} */}
                      </div>
                      <hr className="my-4"></hr>
                      {activeTab === "tentang" && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: event.description,
                          }}
                        />
                      )}
                      {activeTab === "prasyarat" && (
                        <Row>
                          {event.preconditionIds?.map((element) => (
                            <Col key={element._id} className="my-2" md="6">
                              <div className="d-flex align-items-center">
                                <Badge className="bg-hievent py-2 px-2" pill>
                                  {" "}
                                </Badge>
                                <span
                                  className="text-sm m-0 ml-2"
                                  style={{ fontWeight: 700 }}
                                >
                                  {element.name}
                                </span>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                      {activeTab === "review" && <span>-</span>}
                      <hr className="my-4"></hr>
                      <Row>
                        <Col>
                          <h3
                            className="color-black"
                            style={{ fontWeight: 700 }}
                          >
                            Grup Event
                          </h3>
                          <Link to={event?.groupLink} target="_BLANK">
                            {event?.groupLink ? event?.groupLink : "-"}
                          </Link>
                        </Col>
                      </Row>
                      <hr className="my-4"></hr>
                      <Row>
                        <Col>
                          <h3
                            className="color-black"
                            style={{ fontWeight: 700 }}
                          >
                            Materi Event
                          </h3>
                          <Link to={event?.accessEventLink} target="_BLANK">
                            {event?.accessEventLink
                              ? event?.accessEventLink
                              : "-"}
                          </Link>
                        </Col>
                      </Row>
                      <hr className="my-4"></hr>

                      <Row>
                        <Col>
                          <h3
                            className="color-black"
                            style={{ fontWeight: 700 }}
                          >
                            Benefit
                          </h3>
                          <Row>
                            {event.benefitIds?.map((element) => (
                              <Col key={element._id} className="my-2" md="6">
                                <div className="d-flex align-items-center">
                                  <Badge className="bg-hievent py-2 px-2" pill>
                                    {" "}
                                  </Badge>
                                  <span
                                    className="text-sm m-0 ml-2"
                                    style={{ fontWeight: 700 }}
                                  >
                                    {element.name}
                                  </span>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}

            {/* Benefit Event */}
            {/* <Card className="shadow mt-4">
              <CardBody className="p-4">
                <h3
                  className="text-lg color-hievent"
                  style={{ fontWeight: 700 }}
                >
                  Link absensi
                </h3>
                <h3 className="text-success">{linkAbsensi}</h3>
                <Button
                  className="btn btn-sm px-3"
                  color="primary"
                  onClick={() => copyLink()}
                >
                  Salin link
                </Button>
                <p className="text-sm mt-2">
                  *Bagikan Link Absensi kepada peserta event agar mereka dapat
                  mengisi kehadiran / absensi pada Event ini baik di Zoom, Gmeet
                  / di WhatsApp. Jika Event ini bersertifikat, maka Peserta
                  event akan mendapatkan sertifikat secara otomatis dengan
                  mengisi kehadiran melalui link diatas.
                </p>
              </CardBody>
            </Card> */}

            {/* Link Absensi */}
            {/* <Card className="shadow mt-4">
              <CardBody className="p-4">
                <h3>Link absensi dengan Barcode</h3> */}
            {/* <h3 className="text-success">{linkAbsensi}</h3> */}
            {/* <Col className="mt-3 text-center" lg="12">
                  <img
                    id="qrcode-absen"
                    src={codeAbsen}
                    style={{ width: 300 }}
                  ></img>
                </Col>
                <Col className="mt-3 text-center" lg="12">
                  <Button
                    className="btn btn-sm"
                    color="primary"
                    onClick={(event) => downloadQRCode(event)}
                  >
                    Download QRCode
                  </Button>
                </Col>
                <p className="text-sm mt-3">
                  *Perintahkan Peserta Event untuk meng-scan Barcode diatas ini.
                  agar mereka dapat mengisi kehadiran pada Event ini. Jika Event
                  ini bersertifikat, maka Peserta event akan mendapatkan
                  sertifikat secara otomatis dengan mengabsen melalui scan
                  QRCode diatas.
                </p>
                <p className="text-sm mt-2">
                  *Mengisi Kehadiran menggunakan QRCode lebih disarankan untuk
                  Event yang bersifat Offline.
                </p>
              </CardBody>
            </Card> */}

            {/* Ruang Sertifikat */}
            {event.certifiedEvent && pathname === "ruang-sertifikat" ? (
              <>
                <Card className="shadow mt-4">
                  <CardBody className="p-4">
                    <h3>Sertifikat</h3>
                    <p className="text-sm m-0 mt-2">
                      *Sertifikat akan dibagikan secara otomatis kepada Peserta
                      yang sudah mengisi absen pada Link Absensi.
                    </p>
                    <button
                      onClick={(event) => CertificatePreview(event)}
                      className="btn btn-sm btn-warning mb-2"
                    >
                      Unduh Preview
                    </button>
                    <Col id="certificate" md="12" className="p-0">
                      <img
                        className="w-100 h-100 rounded-lg"
                        style={{ objectFit: "cover" }}
                        src={
                          event.certificateFile.url
                            ? event.certificateFile.url
                            : process.env.REACT_APP_urlCertificate
                        }
                        alt="hievents"
                        crossOrigin="true"
                      />
                      <h1
                        className="w-100 text-center display-4 certificate-name"
                        style={{ fontWeight: "bolder" }}
                      >
                        NAMA PESERTA
                      </h1>
                      <div className="w-100 text-center certificate-logo">
                        <img
                          className="certificate-logo-detail"
                          src={logo}
                          alt="hievents"
                        />
                      </div>
                      <div className="w-100 certificate-code">
                        <img src={linkCode} className="qrcode-detail"></img>
                      </div>
                    </Col>
                  </CardBody>
                </Card>

                {/* Tentang Event */}
                <Card
                  className="shadow mt-4 mb-6"
                  style={{
                    zIndex: 9999,
                  }}
                >
                  <CardBody className="p-4">
                    <Row>
                      <Col>
                        <div className="d-flex flex-row align-items-center mb-3">
                          <h3
                            className="mr-3 text-md"
                            style={{
                              fontWeight: 500,
                              color:
                                activeTab === "tentang" ? "black" : "#C4C4C4",
                              cursor: "pointer",
                            }}
                            onClick={() => setActiveTab("tentang")}
                          >
                            Tentang Event
                          </h3>
                          <h3
                            className="mr-3 text-md"
                            style={{
                              fontWeight: 500,
                              color:
                                activeTab === "prasyarat" ? "black" : "#C4C4C4",
                              cursor: "pointer",
                            }}
                            onClick={() => setActiveTab("prasyarat")}
                          >
                            Prasyarat
                          </h3>
                          <h3
                            className="mr-3 text-md"
                            style={{
                              fontWeight: 500,
                              color:
                                activeTab === "review" ? "black" : "#C4C4C4",
                              cursor: "pointer",
                            }}
                            onClick={() => setActiveTab("review")}
                          >
                            Review
                          </h3>
                        </div>
                        <hr className="my-4"></hr>
                        {activeTab === "tentang" && (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: event.description,
                            }}
                          />
                        )}
                        {activeTab === "prasyarat" && (
                          // Render Prasyarat content here
                          <span>Prasyarat content goes here</span>
                        )}
                        {activeTab === "review" && (
                          // Render Review content here
                          <span>Review content goes here</span>
                        )}
                        <hr className="my-4"></hr>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </>
            ) : null}

            {/* Peserta yang Bergabung */}
            {/* <Card className="shadow mt-4">
              <CardBody className="p-4">
                <h3>Siapa saja yang bergabung</h3>
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <i className="fa fa-users fa-lg"></i>
                  <h3 className="text-success mb-0 ml-2">
                  {userRegister.count}
                    {event.maximumTicket > 0
                      ? " / " + event.maximumTicket
                      : ""}{" "}
                    Peserta telah bergabung
                    </h3>
                </div>
                <hr className="m-3"></hr> */}
            {/* {listUserRegister()} */}
            {/* </CardBody> */}
            {/* {userRegister.count > 0 ? (
                <CardFooter
                  className="d-flex rounded-bottom border-0 p-3 align-items-center bg-gradient-primary"
                  to={"/user-terdaftar/" + event._id + "=" + userRegister.count}
                  tag={Link}
                >
                  <span className="w-100 text-center text-white">
                    Lihat Semua
                  </span>
                </CardFooter>
              ) : null} */}
            {/* </Card> */}

            {/* Peserta yang absensi */}
            {/* <Card className="shadow mt-4 mb-6">
              <CardBody className="p-4">
                <h3>Siapa saja yang mengisi absen</h3>
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <i className="fa fa-users fa-lg"></i>
                  <h3 className="text-success mb-0 ml-2">
                    {userAttend.count} peserta mengisi absen
                  </h3>
                </div>
                <hr className="m-3"></hr> */}
            {/* {listUserAttend()} */}
            {/* </CardBody> */}
            {/* {userAttend.count > 0 ? (
                <CardFooter
                  className="d-flex rounded-bottom border-0 p-3 align-items-center bg-gradient-primary"
                  to={"/user-absensi/" + event._id + "=" + userAttend.count}
                  tag={Link}
                >
                  <span className="w-100 text-center text-white">
                    Lihat Semua
                  </span>
                </CardFooter>
              ) : null} */}
            {/* </Card> */}
          </div>

          {pathname.includes("event-terdaftar") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                height: "60px",
                padding: "10px 20px",
                position: "fixed",
                bottom: 0,
                left: 0,
                boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: "row",
                gap: "10px",
                backgroundColor: "white",
                zIndex: 100,
                borderTop: "1px solid #9870C5",
              }}
            >
              <Link
                to="#"
                className="bg-hievent"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  background: "#62C4EF",
                  height: "40px",
                  borderRadius: "5px",
                }}
                disabled
              >
                <span style={{ fontWeight: 600, color: "white" }}>
                  Gabung Event
                </span>
              </Link>
            </div>
          )}

          {pathname.includes("event-selesai") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                height: "60px",
                padding: "10px 20px",
                position: "fixed",
                bottom: 0,
                left: 0,
                boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: "row",
                gap: "10px",
                backgroundColor: "white",
                zIndex: 100,
                borderTop: "1px solid #9870C5",
              }}
            >
              <button
                to="#"
                className="bg-hievent"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                  background:
                    match.params.absen === "attended" ? "#5BE462" : "#766A6A",
                  height: "40px",
                  borderRadius: "5px",
                  outline: "none",
                  border: "none",
                }}
                disabled
              >
                <span style={{ fontWeight: 600, color: "white" }}>
                  {match.params.absen === "attended" ? "Hadir" : "Tidak Hadir"}
                </span>
              </button>
            </div>
          )}

          {pathname.includes("ruang-sertifikat") && (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                height: "60px",
                padding: "10px 20px",
                position: "fixed",
                bottom: 0,
                left: 0,
                boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
                flexDirection: "row",
                gap: "10px",
                backgroundColor: "white",
                zIndex: 100,
                borderTop: "1px solid #9870C5",
              }}
            >
              <Row>
                <Col>
                  <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                    Total Harga:{" "}
                    {capitalizeFirstLetter(
                      event.paidStatus === "free" ? "Rp.0,00" : "Berbayar"
                    )}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      flexDirection: "row",
                      gap: "5px",
                    }}
                  >
                    <h3
                      className="color-hievent text-sm"
                      style={{ fontWeight: 400 }}
                    >
                      {moment(event?.startDate).isBefore(moment(new Date()))
                        ? "Selesai"
                        : "Tersedia"}
                    </h3>
                    <Badge className="bg-hievent py-1 px-1 mb-2" pill>
                      {" "}
                    </Badge>
                    <h3
                      className="color-hievent text-sm"
                      style={{ fontWeight: 400 }}
                    >
                      {moment(event?.startDate).isBefore(moment(new Date()))
                        ? "Selesai"
                        : "Tersedia"}
                    </h3>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  width: "50%",
                }}
              >
                <Col>
                  <Link
                    to="#"
                    className="bg-hievent"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      background: moment(event?.startDate).isBefore(
                        moment(new Date())
                      )
                        ? "#737373"
                        : "#62C4EF",
                      height: "40px",
                      borderRadius: "5px",
                    }}
                    disabled={moment(event?.startDate).isBefore(
                      moment(new Date())
                    )}
                  >
                    <span style={{ fontWeight: 600, color: "white" }}>
                      {moment(event?.startDate).isBefore(moment(new Date()))
                        ? "Selesai"
                        : "Gabung"}
                    </span>
                  </Link>
                </Col>
              </Row>
            </div>
          )}

          {USER._id !== event.user?._id ? (
            window.location.pathname
              .replace(match.params.slug, "")
              .includes("absensi") ? (
              !validationAttends() ? (
                navigation.push(`/event-terdaftar/${match.params.slug}`)
              ) : (
                <AttendEvent event={event} />
              )
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  padding: "15px 20px",
                  position: "fixed",
                  bottom: 0,
                  left: 0,
                  boxShadow: "0px -1px 10px rgba(0, 0, 0, 0.1)",
                  flexDirection: "row",
                  gap: "10px",
                  backgroundColor: "white",
                  zIndex: 100,
                  borderTop: "1px solid #9870C5",
                }}
              >
                <Row>
                  <Col>
                    <h3 className="color-hievent" style={{ fontWeight: 700 }}>
                      {capitalizeFirstLetter(
                        event.paidStatus === "free"
                          ? "Gratis"
                          : "Total Harga: Berbayar"
                      )}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        flexDirection: "row",
                        gap: "5px",
                      }}
                    >
                      <h3
                        className="color-hievent text-sm"
                        style={{ fontWeight: 400 }}
                      >
                        Tiket
                      </h3>
                      <Badge className="bg-hievent py-1 px-1 mb-2" pill>
                        {" "}
                      </Badge>
                      <h3
                        className="color-hievent text-sm"
                        style={{ fontWeight: 400 }}
                      >
                        {moment(event?.startDate).isBefore(moment(new Date()))
                          ? "Selesai"
                          : "Tersedia"}
                      </h3>
                    </div>
                  </Col>
                </Row>
                <Row
                  style={{
                    width: "50%",
                  }}
                >
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      {USER._id !== event.user?._id ? (
                        userRegistered ? (
                          <AttendEvent event={event} />
                        ) : (
                          <EventRegister
                            event={event}
                            accessGroupLink={accessGroupLink}
                          />
                        )
                      ) : event.privateEvent ? (
                        <div className="py-2 bg-hievent text-center rounded-lg mt-2">
                          <span
                            className="text-white"
                            style={{ fontWeight: 700 }}
                          >
                            Kode akses event
                          </span>
                          <h2 className="text-white mb-0">
                            {event.generatedEventCode}
                          </h2>
                        </div>
                      ) : null}
                    </div>
                    {/* <Link
                      to="#"
                      className="bg-hievent"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                        background: moment(event?.startDate).isBefore(
                          moment(new Date())
                        )
                          ? "#737373"
                          : "#62C4EF",
                        height: "40px",
                        borderRadius: "5px",
                      }}
                      disabled={moment(event?.startDate).isBefore(
                        moment(new Date())
                      )}
                    > */}
                    {/* <span style={{ fontWeight: 600, color: "white" }}>
                        {moment(event?.startDate).isBefore(moment(new Date()))
                          ? "Selesai"
                          : "Gabung"}
                      </span> */}
                    {/* <EventRegister
                      event={event}
                      accessGroupLink={accessGroupLink}
                    /> */}
                    {/* </Link> */}
                  </Col>
                </Row>
              </div>
            )
          ) : null}
        </div>
      )}

      <EventPopuler
        data={{
          title: "Event Lainnya",
          url: "eventTypeId=6367a00ca563f77cf87ebba3",
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    USER: state.userReducer.userState,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    SET_USER: (value) => dispatch({ type: "SET_USER", value: value }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Index);
